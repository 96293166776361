@font-face {
  font-family: 'din_condensed';
  src: local('din_condensed'), url(assets/fonts/din-condensed-bold.ttf) format('truetype');
  font-weight: bold;
}

.no-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #dddddd;
  /*box-shadow: inset 0 0 5px #dddddd;*/
  border-radius: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 4px;
}